<template>
  <div>
    <div v-if="classRoom">
      <div class="dispflex" style="flex-wrap: inherit;">
        <div class="dk_icon" style="width: 10%">
          <span class="has-float-label" style="margin-top: 4%">
            <select
              @change="changeTerm(selectTerm,null)"
              class="form-control form-input"
              id="deptlistid"
              v-model="selectTerm"
            >
              <option :value="null">-- Select--</option>
              <option
                v-for="(item, index) in termList"
                :key="index"
                :value="item._id"
              >
                {{ item.name }}
              </option>
            </select>
            <label for="clslistid">Select Term </label>
          </span>
        </div>
        <div class="dk_icon" style="margin-right: 20%; width: 10%">
          <span class="has-float-label" style="margin-top: 4%">
            <select @change="getCurriculumInfoWithSubject(selectSubject)" class="form-control form-input" id="deptlistid" v-model="selectSubject">
              <option :value="null">--Select--</option>
              <option
                v-for="(item, index) in teacherAllowSubjects"
                :key="index"
                :value="item._id"
              >
                {{ item.subjectName }}
              </option>
            </select>
            <label for="clslistid">Select Subject </label>
          </span>
        </div>
        
        <!-- <div style="margin-top: 10px" class="flexitm"> -->
                <!-- <a href="https://vidhyaan.sgp1.digitaloceanspaces.com/Development/Curriculum.xlsx" target="_blank" >Download Curriculum Upload Template </a> -->
          <!-- </div> -->
          <div style="margin-top: 0px" class="flexitm">
          <div class="upload-btn-wrapper" style="border-radius: 40px;">
            
                  <!-- <button  class="upbtn" style="margin-left: 0px;margin-right: 13px;" >Upload a file</button> -->
                      <input style="left: 7px;top: 4px;" hidden
                      type="file"
                      id="importExportID"
                      ref="schoolImagechangeFile"
                      name="schoolImagechangeFile"
                      accept=".xls,.xlsx"
                      @change="updateCurriculamFile('schoolImagechangeFile')"
                      />
                <!-- </div>  @change="updateFile('schoolImage','schoolImagechangeFile')" -->
          </div>
        
        </div>
      
          <div class="text-right dk_iconsml widjetcontent">
            <button
                  class="btn gry_bg tooltipt mpopup twoicn"
                  :disabled="!selectTerm || !selectSubject"
            @click="openCurriculumPopUp"
                >
                <i class="fas fa-list-ul"></i>
                  <span class="tooltiptext" style="display: inline-block;pointer-events: initial;" v-if="!selectTerm">Select Term</span>
                  <span class="tooltiptext" style="display: inline-block;pointer-events: initial;" v-else-if="!selectSubject">Select Subject</span>
                  <span class="tooltiptext" v-else>Add Curriculum</span>


                  <!-- :style="!selectTerm || !selectSubject ? 'display: none;pointer-events: none' : 'display: none;pointer-events: none'"
                  display: inline-block;pointer-events: initial;  -->

                </button>
            <button  @click.prevent="downloadFile"
                  class="btn gry_bg tooltipt mpopup twoicn"
                >
                  <i class="fas fa-download"></i>
                 
                  <span class="tooltiptext">Download Curriculum Upload Template</span>
                </button>
                <button  @click.prevent="uploadbtn"
                  class="btn gry_bg tooltipt mpopup twoicn"
                >
                  <i class="fas fa-upload"></i>
                 
                  <span class="tooltiptext">Upload</span>
                </button>
              
              
       
          <!-- <button
            style="width: 100%;margin-left: 6px;"
            type="button"
            class="btn btnsml"
            id="svbtn"
            :disabled="!selectTerm || !selectSubject"
            @click="openCurriculumPopUp"
          >
              <span>Add Curriculum</span>
          </button> -->
        
        </div>
      </div>
    
      <!-- <div class="widjetcontent tbllist">
        <div class="crtfeeplancont" style="padding: 0 13px">
          <div
            class="widjethdr dispflex"
            style="align-items: baseline; padding: 10px 0"
          ></div>
          <div class="restable">
            <div class="resrow resheader">
              <div class="cell">Subject</div>
              <div class="cell">Topics</div>
              <div class="cell">Chapter No</div>
              <div class="cell">No of Classes</div>
              <div class="cell">Status</div>
              <div class="cell">From Date</div>
              <div class="cell">To Date</div>
              <div class="cell">Action</div>
            </div>
            <div
              
              v-for="(data, index) in curriculumInfo"
              :key="index"
            >
            <div
              class="resrow"
              
            >
              <div class="cell">{{ data.subject ? data.subject.subjectName : '' }}</div>
              <div class="cell">
                {{ data.topic  }}
              </div>
              <div class="cell">
                {{ data.order  }}
              </div>
              <div class="cell">
                {{ data.noOfClass }}
              </div>
              <div class="cell">
                {{ data.status  }}
              </div>
              <div class="cell">
                {{
                  data.startDate ? data.startDate.substring(0, 10) : ''
                }}
              </div>
              <div class="cell">
                {{
                  data.toDate ? data.toDate.substring(0, 10) : ''
                }}
              </div>
              <div class="cell">
                <b-dropdown
                      id="dropdown-1"
                      text=""
                      right
                      style="margin-right: 3px"
                    >
                     
                      <b-dropdown-item
                      
                      @click.prevent="viewmaterialCurriculamPopup(index)"
                        >Materials</b-dropdown-item
                      >

                    
                    </b-dropdown>
                <button
                  class="btn round orange tooltipt"
                  @click.prevent="viewUpdateCurriculumPopUp(index)"
                >
                  <i class="far fa-eye"></i
                  ><span class="tooltiptext">View</span>
                </button>
                <button
                  class="btn round orange tooltipt"
                  @click="openUpdateCurriculumPopUp(index)"
                >
                  <i class="fas fa-edit"></i
                  ><span class="tooltiptext">Edit</span>
                </button>

                <button
                  @click="confirmDelete(data._id)"
                  class="btn round red tooltipt"
                >
                  <i class="far fa-trash-alt"></i>
                  <span class="tooltiptext">Delete</span>
                </button>
              </div>
            </div>
          </div>
          </div>
          <div
            class="widjet"
            v-if="curriculumInfo.length == 0"
            style="height: 198px; box-shadow: none"
          >
            <div style="padding-top: 92px">
              <span style="margin-left: 42%">No Data Found</span>
            </div>
          </div>
        </div>
      </div> -->
      <div
      class="row"
      style="margin-top: 10px"
      
    >
      <div class="col-lg-12">

        <div class="widjet">
          <div class="admintbl pb-3 mt-1">
            <form
              data-vv-scope="crtAdhocFeeValidate"
              id="crtfeeplans"
              accept-charset="utf-8"
            >
      <div class="row cust-bg" style="background: #e3ecff;
    
    border-radius: 6px;
    margin: 8px;">
                <div class="col-1 cell" style="border-top: 0px solid #efefef;">Subject</div>
                <div class="col-2 cell" style="border-top: 0px solid #efefef;">Topics</div>
                <div class="col-1 cell" style="border-top: 0px solid #efefef;">Chapter No</div>
                <div class="col-1 cell" style="border-top: 0px solid #efefef;">No of Classes</div>
                <div class="col-2 cell" style="border-top: 0px solid #efefef;">From Date</div>
				        <div class="col-2 cell" style="border-top: 0px solid #efefef;">To Date</div>
                <div class="col-1 cell"  style="padding-left: 16px;border-top: 0px solid #efefef;">Status</div>
                <div class="col-2 cell" style="border-top: 0px solid #efefef;text-align: center">Action</div>
              </div>
              <div v-for="(data, index) in Object.keys(curriculumInfo)" :key="index">
                <div class="row cust-lr" style="margin-right: 0px;margin-left: 0px;">
                  <div
                    class="col-1 cell"
                  >
                    {{ curriculumInfo[data].subject ? curriculumInfo[data].subject.subjectName : '' }}
                  </div>
                  <div class="col-2 cell">
                    <span v-if="!curriculumInfo[data].isView">{{ curriculumInfo[data][data] && curriculumInfo[data][data].length > 0 ? curriculumInfo[data][data][0].topic : '' }}</span>
                    
                  </div>
                 
                  <div class="col-1 cell">
                    <span v-if="!curriculumInfo[data].isView">{{ curriculumInfo[data][data] && curriculumInfo[data][data].length > 0 ? curriculumInfo[data][data][0].chapterNo : '' }}</span>
                  </div>
                  <div class="col-1 cell">
                    <span v-if="!curriculumInfo[data].isView">{{ curriculumInfo[data][data] && curriculumInfo[data][data].length > 0 ? curriculumInfo[data][data][0].noOfClass : '' }}</span>
                  </div>
                  <div class="col-2 cell" >
                    <span v-if="!curriculumInfo[data].isView">{{ curriculumInfo[data][data] && curriculumInfo[data][data].length > 0 ? curriculumInfo[data][data][0].startDate : '' }}</span>
                  </div>
                  <div class="col-2 cell" >
                    <span v-if="!curriculumInfo[data].isView">{{ curriculumInfo[data][data] && curriculumInfo[data][data].length > 0 ? curriculumInfo[data][data][0].toDate : '' }}</span>
                  </div>
                  <div class="col-1 cell" style="text-align: center;">
                    <span v-if="!curriculumInfo[data].isView">{{ curriculumInfo[data][data] && curriculumInfo[data][data].length > 0 ? curriculumInfo[data][data][0].status : '' }}</span>
                  </div>

                  <div class="col-2 cell" style="text-align: center;">
                      <button
                        v-if="curriculumInfo[data].isView"
                        class="btn round orange tooltipt"
                        style="background: #b3a995"
                        @click.prevent="showViewClose(data)"
                      >
                        <i class="fas fa-angle-up"></i>
                      </button>
                      <button
                        v-if="!curriculumInfo[data].isView"
                        class="btn round orange tooltipt"
                        style="background: #b3a995"
                        @click.prevent="showView(data)"
                      >
                        <i class="fas fa-angle-down"></i>
                      </button>

                     
                      <button
                        v-if="!curriculumInfo[data].isView"
                        class="btn round red tooltipt"
                        @click.prevent="confirmDelete(curriculumInfo[data].subject._id,selectTerm)"
                      
                      >
                        <i class="far fa-trash-alt"></i>
                        <span class="tooltiptext">Delete</span>
                      </button>
                      <button
                      v-if="curriculumInfo[data].isView"
                        style="width: 67px"
                        type="button"
                        class="btn btnsml"
                        id="svbtn"
                        @click.prevent="addTopics(data)"
                      ><span>Add</span>
                        
                      </button>
                      <button
                      v-if="curriculumInfo[data].isView"
                        style="width: 70px"
                        type="button"
                        class="btn btnsml"
                        id="svbtn"
                        @click.prevent="saveTermSubjects(data,curriculumInfo[data].subject._id)"
                      >
                        Save
                      </button>
                  </div>
                </div>
                <div v-if="curriculumInfo[data].isView">
                  <div
                    class="row cust-lr" style="margin-right: 0px;margin-left: 0px;"
                    v-for="(ter, issndex) in curriculumInfo[data][data]"
                    :key="issndex"
                  >
                  <div class="col-1 cell"></div>
                    <div class="col-2 cell">
                     
                      <input style="margin-top: 5px;"
                          v-validate="'required'"
                          v-model="ter.topic"
                          id="stdFirstName"
                          :name="issndex + 'topic'"
                        />
                        <span class="required" v-if="errors.first(`crtAdhocFeeValidate.${issndex + 'topic'}`)">required </span>
                    </div>
                    <div class="col-1 cell">
                     
                     <input style="margin-top: 5px;width: 121%;"
                         v-validate="'required|numeric|min:0'"
                         v-model="ter.chapterNo"
                         type="number"
                         :min="0"
                         id="stdFirstName"
                         :name="data + issndex + 'chapterNo'"
                       />
                       <span class="required" v-if="errors.first(`crtAdhocFeeValidate.${data + issndex + 'chapterNo'}`)"> required </span>
                   </div>
                   <div class="col-1 cell">
                     
                     <input style="margin-top: 5px;width: 121%;"
                         v-validate="'required|numeric|min:0'"
                         v-model="ter.noOfClass"
                         type="number"
                        
                         id="stdFirstName"
                         :name="data + issndex + 'noOfClass'"
                       />
                       <span class="required" v-if="errors.first(`crtAdhocFeeValidate.${data + issndex + 'noOfClass'}`)"> required </span>
                   </div>
                    
                    <div class="col-2 cell">
                      <input style="margin-top: 5px;width: 100%;"
                         v-model="ter.startDate"
                         type="date"
                        
                         id="stdFirstName"
                         :name="data + issndex + 'startDate'"
                       />
                       <span class="required" v-if="errors.first(`crtAdhocFeeValidate.${data + issndex + 'startDate'}`)"> required </span>
                    </div>
                    <div class="col-2 cell">
                      <input style="margin-top: 5px;width: 100%;"
                        
                         v-model="ter.toDate "
                         type="date"
                        
                        
                         id="stdFirstName"
                         :name="data + issndex + 'toDate'"
                       />
                       <span class="required" v-if="errors.first(`crtAdhocFeeValidate.${data + issndex + 'toDate'}`)"> required </span>
                    </div>
                    <div class="col-1 cell" style="text-align: center;">
                      <select style="width: 116px;margin-top: 9px;"
                            v-validate="'required'"
                            v-model="ter.status"
                            :name="data + issndex + 'status'"
                     
                        id="deptlistid"
                      >
                        <option :value="null">--Select--</option>
                        <option
                          v-for="(item, index) in statusList"
                          :key="index"
                          :value="item"
                        >
                          {{ item }}
                        </option>
                      </select>
                      <span class="required" v-if="errors.first(`crtAdhocFeeValidate.${data + issndex + 'status'}`)"> required </span>

                 </div>

                    <div class="col-2 cell" style="text-align: center;">
                      <b-dropdown
                      id="dropdown-1"
                      text=""
                      right
                      style="margin-right: 3px"
                    >
                     
                      <b-dropdown-item
                      
                      @click.prevent="viewmaterialCurriculamPopup(data,issndex,ter._id)"
                        >Materials</b-dropdown-item
                      >

                    
                    </b-dropdown>
               

                <button
                  @click.prevent="removeCurriculamSub(data,issndex,ter._id)"
                  class="btn round red tooltipt"
                >
                  <i class="far fa-trash-alt"></i>
                  <span class="tooltiptext">Delete</span>
                </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        
      </div>
    </div>
    </div>
    <div class="widjet" v-else style="height: 235px;">
          <div style="padding-top: 92px;" >
            <!-- <span style="margin-left: 42%;" v-if="isLoader">Select Class And ClassRoom</span> -->
            <span style="margin-left: 42%;">Please Select Classroom...</span>
          </div>
        </div>

    <b-modal id="addCurriculumPopUp" style="width: 100%" no-close-on-backdrop
        no-close-on-esc>
        <button type="button" class="close"  @click.prevent="closeCurriculumPopUp">×</button>
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2> {{ curriculumCreateData._id ? 'Edit Curriculum' : 'Add Curriculum'}}</h2>
        <br />
        <div>
          <form id="stuprofile" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-3 mt-3">
                <span class="has-float-label">
                  <select
                    disabled
                    class="form-control form-input"
                    id="deptlistid"
                    v-model="selectTerm"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(item, index) in termList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <label for="clsnameid"
                    >Term <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-3 mt-3">
                <span class="has-float-label">
                  <select disabled
                   v-model="selectSubject"
                    class="form-control form-input"
                    id="subjectId"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in teacherAllowSubjects"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.subjectName }}
                    </option>
                  </select>
                  <label for="clsnameid"
                    >Select Subject <span class="required">*</span></label
                  >
                </span>
              </div>
              <!-- </div> -->
              <!-- <div class="form-row"> -->
                <div class="form-group input-group col-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    v-model="curriculumCreateData.topic"
                    type="text"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >Topic <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    v-model="curriculumCreateData.chapterNo"
                    type="number"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >Chapter No <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    v-model="curriculumCreateData.noOfClass"
                    type="number"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >No of classes <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-3 mt-3">
                <span class="has-float-label">
                  <select
                    class="form-control form-input"
                    id="subjectId"
                    v-model="curriculumCreateData.status"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in statusList"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <label for="clsnameid"
                    >Status <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    v-model="curriculumCreateData.startDate"
                    type="date"
                    :min="minDate"
                    :max="maxDate"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >Start Date <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-3 mt-3">
                <span class="has-float-label">
                  <input
                     :max="maxDate"
                    :min="curriculumCreateData.startDate"
                    v-model="curriculumCreateData.toDate"
                    type="date"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >End Date<span class="required">*</span></label
                  >
                </span>
              </div>
            
            </div>
          
          
            <div
              class=" mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <!-- <div class="dk_icon"></div> -->

              <div class="text-center dk_iconsml">
                <button
                  :disabled="!curriculumCreateData.topic || !curriculumCreateData.noOfClass || !curriculumCreateData.status || !curriculumCreateData.startDate || !curriculumCreateData.toDate"
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click="createSingleTermSubjects"
                >
                    <span>Save</span>
                </button>
                <button
                  type="button"
                  class="btn btncl clsmdl"
                  id="clbtn"
                  @click="closeCurriculumPopUp"
                >
                     <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
    <b-modal id="viewCurriculumPopUp" style="width: 50%" no-close-on-backdrop
        no-close-on-esc>
        <button type="button" class="close"  @click.prevent="closeViewCurriculumPopUp">×</button>
      <div class="contact-form">
        <h2>View</h2>
        <br />
      
        
          <form id="stuprofile" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <select
                    disabled
                    class="form-control form-input"
                    id="deptlistid"
                    v-model="selectTerm"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(item, index) in termList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <label for="clsnameid"
                    >Term <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <select
                    disabled
                    class="form-control form-input"
                    id="subjectId"
                    v-model="curriculumCreateData.subject"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in subjectList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.subjectName }}
                    </option>
                  </select>
                  <label for="clsnameid"
                    > Subject <span class="required">*</span></label
                  >
                </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                  disabled
                    v-model="curriculumCreateData.topic"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >Topic <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                  disabled
                    v-model="curriculumCreateData.noOfClass"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >No Of Classes <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    v-model="curriculumCreateData.status"
                    disabled
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >Status <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    v-model="curriculumCreateData.startDate"
                    type="date"
                    disabled
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >Start Date <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    v-model="curriculumCreateData.toDate"
                    type="date"
                    disabled
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >To Date <span class="required">*</span></label
                  >
                </span>
              </div>
            </div>

            <div
              class=" mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <!-- <div class="dk_icon"></div> -->

              <div class="text-center dk_iconsml">
                <button
                  type="button"
                  class="btn btncl clsmdl"
                  id="clbtn"
                  @click="closeViewCurriculumPopUp"
                >
                     <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      
    </b-modal>
    <b-modal id="sortingTopicPopUp" size="sm" style="width: 50%;" >
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Sort By Topic</h2>
        <br />
      
          <app-dragabble
               class="flex flex-wrap dropzone min-height-50"
               style="cursor: grab;"
               :list="curriculumInfo"
               :group="{ name: 'regions', put: true }"
            >

            <div class="row mt-4 countsection" style="padding-left: 15%;padding-right: 18%;" v-for="(component, cmpIndex) in curriculumInfo" :key="cmpIndex">
            <div class="col-lg-12">
              <div class="countsect p-2 ml-2 mr-2">
            {{  cmpIndex + 1 }}.   {{ component.topic }}
              </div>
            </div>
          </div>

            </app-dragabble>

          
           <div
              class=" mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
             

              <div class="text-center dk_iconsml">
                <button
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="updateSortingTopic"
                >
                    <span>Save</span>
                </button>
                <button
                  type="button"
                  class="btn btncl clsmdl"
                  id="clbtn"
                  @click="closeviewSortingPopUp"
                >
                     <span>Cancel</span>
                </button>
              </div>
            </div>
        </div>
      
    </b-modal>
    <b-modal
      id="materialCurriculamPopup"
      style="width: 100%"
     
    >
    <button type="button" class="close"  @click.prevent="closePoup">×</button>

      <div class="contact-form">
        <h2>Materials</h2>
        <br />
        <div>
          <form
            id="crtfeeplans"
            accept-charset="utf-8"
          >
          <div class="row">
         
          <div class="col-lg-12">
              
               <div class="form-row" >
                           <input
                            type="file"
                            id="importExportID"
                            ref="attachDoc"
                            name="attachDoc"
                            accept="application/pdf,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/jpg, image/png"
                            @change="updateFile('attachDoc')"
                           />
              </div>
              <div class="form-row" style="width: 100%;" v-for="(doc, indexDoc) in documentList" :key="indexDoc">
               <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input 
                    v-model="doc.name"
                    disabled
                    class="form-control form-input"
                    id="fpstartdateid"
                  />
                  <label  for="fpstartdateid">File Name</label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input 
                    v-model="doc.date"
                    disabled
                    class="form-control form-input"
                    id="fpstartdateid"
                  />
                  <label  for="fpstartdateid">File Upload Date</label>
                </span>
              </div>
               <div class="form-group input-group col-md-2 mt-3 ">
                <button class="btn btn-doc btn-sm" style="background-color: darkseagreen;"  @click.prevent="preViewDocument(doc.url.Location)">
                              View
                            </button>
                            <button class="btn btn-doc btn-sm" style="margin-left: 7px;background-color: indianred;" @click.prevent="removeDocument(indexDoc,doc.url.key)">
                              Delete
                            </button>
                            <!-- //key -->
                 <!-- <button class="btn round red tooltipt"   style="height:1.8rem"><i class="far fa-trash-alt"></i><span class="tooltiptext">Delete</span></button> -->
               
              </div>
              </div>
          </div>
          </div>
          </form>

          <div class="mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
            <!-- <div class="dk_icon"></div> -->
            <div class="text-center dk_iconsml">
            
             
              <button
                @click.prevent="closePoup"
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import ViService from "@/services/ViService";
import secureUI from "../../../utils/secureUI";
import draggable from "vuedraggable";

export default {
  name: "teacher",
  props: {
    classId: {
      type: String,
    },
    classRoom: {
      type: String,
    },
    assignedSubjects: {
      type: Array
    }
  },
  data() {
    return {
      selectSubject: null,
      selectTerm: null,
      termList: [],
      minDate: null,
      maxDate: null,
      documentList: [],
      assignmentView: "List",
      showSetupTimeTable: false,
      curriculumInfo: {},
      isCreateNew: false,
      teacherAllowSubjects: [],
      subjectList: [],
      addMaterial: {

      },
      curriculumCreateData: {
        subject: null,
        topic: "",
        noOfClass: null,
        status: "",
        startDate: null,
        toDate: null,
        chapterNo: ''
      },
      addTopicDetails: {
        topic: "",
        noOfClass: null,
        status: "",
        startDate: null,
        toDate: null,
      },
      statusList: ["Yet to start","Started", "In-Progress", "Completed"],
    };
  },
  created() {
    this.getSubjectList();
    this.getClassTerm();
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
  },

  methods: {
    downloadFile(){
      window.open('https://vidhyaan.sgp1.digitaloceanspaces.com/Development/Curriculum.xlsx', "_blank");

    },
    uploadbtn(){
      document.getElementById('importExportID').click();
    },
    // removeSub(index) {
    //   this.curriculumCreateData.subjects.splice(index, 1);
    // },
    // removeTop(index) {
    //   this.curriculumCreateData.topics.splice(index, 1);
    // },
    preViewDocument(path) {
      window.open(path, "_blank");
    },
    addTopics(data) {
      this.curriculumInfo[data][data].push({
        _id: null,
        topic: '',
        noOfClass: null,
        startDate: null,
        toDate: null,
        status: '',
        chapterNo: ''
      })
      // for (let x of Object.keys(this.curriculumInfo)) {
      //       for(let y of this.curriculumInfo[x][x]){
      //         y.startDate = y.startDate ? y.startDate.substring(0, 10) : null,
      //         y.toDate = y.toDate ? y.toDate.substring(0, 10) : null
      //       }

      //     //allTermList.push(x);
      //  }

      // this.curriculumCreateData.topics.push({
      //   topic: this.addTopicDetails.topic,
      //   noOfClass: this.addTopicDetails.noOfClass,
      //   startDate: this.addTopicDetails.startDate,
      //   toDate: this.addTopicDetails.toDate,
      //   status: this.addTopicDetails.status,
      // });

      // this.addTopicDetails = {
      //   topic: "",
      //   noOfClass: null,
      //   status: "",
      //   startDate: null,
      //   toDate: null,
      // };
    },

    // addSub(data) {
    //   this.curriculumCreateData.subjects.push({
    //     _id: null,
    //     name: data,
    //   });
    // },
    showView(index) {
      for (let x of Object.values(this.curriculumInfo)) {
          x.isView = false;
     
      }

      this.curriculumInfo[index].isView = true;


    },
    showViewClose(index) {
      this.curriculumInfo[index].isView = false;
     
    },
    async saveTermSubjects(data,subject) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let isFormValid = false;

await this.$validator.validateAll("crtAdhocFeeValidate").then((result) => {
  isFormValid = result;
});

if(isFormValid){

        let allowSave = true;
        // if (this.curriculumInfo.length > 0) {
        //   let details = this.curriculumInfo.find((x) => x.subject == this.curriculumCreateData.subject);
        //   if (details && !this.curriculumCreateData._id) {
        //     allowSave = false;
        //   } else {
        //     allowSave = true;
        //   }
        // } else {
        //   allowSave = true;
        // }
        if (allowSave) {
          let obj = {
            class: this.classId,
            classroom: this.classRoom,
            term: this.selectTerm,
            subject: subject,
            curriculam: this.curriculumInfo[data][data]
          }
          // this.curriculumCreateData["class"] = this.classId;
          // this.curriculumCreateData["classroom"] = this.classRoom;
          // this.curriculumCreateData["term"] = this.selectTerm;
          // if(this.curriculumCreateData._id){
          //   this.curriculumCreateData.subject = this.curriculumCreateData.subject
          // }else{
          //   this.curriculumCreateData["subject"] = this.selectSubject
          // }
         
          
      

          const response = await ViService.viXPost(
            "/academic/curriculumn/save",
            obj,
            userData.token
          );

          if (response.isSuccess) {
            // const resultData = secureUI.secureGet(response.data);
            this.$toasted.success(response.message);
           // this.closeCurriculumPopUp();

         

            await this.getCurriculumInfo(this.selectTerm,this.selectSubject);

            // for (let x of Object.values(this.curriculumInfo)) {
            //   x.isView = false;
            // }

          this.curriculumInfo[data].isView = true;
          } else {
            this.$toasted.error(response.message);
          }
        // } else {
        //   this.$toasted.error("Subject Already Created");
         }
      }else{
        this.$toasted.error('Please fill all the required details');
      }
    }
    },
    async createSingleTermSubjects() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let isFormValid = false;

await this.$validator.validateAll("crtAdhocFeeValidate").then((result) => {
  isFormValid = result;
});

if(isFormValid){

        let allowSave = true;
        // if (this.curriculumInfo.length > 0) {
        //   let details = this.curriculumInfo.find((x) => x.subject == this.curriculumCreateData.subject);
        //   if (details && !this.curriculumCreateData._id) {
        //     allowSave = false;
        //   } else {
        //     allowSave = true;
        //   }
        // } else {
        //   allowSave = true;
        // }
        if (allowSave) {
          // let obj = {
          //   class: this.classId,
          //   classroom: this.classRoom,
          //   term: this.selectTerm,
          //   subject: subject,
          //   curriculam: this.curriculumInfo[data][data]
          // }
          // curriculumCreateData
          this.curriculumCreateData["class"] = this.classId;
          this.curriculumCreateData["classroom"] = this.classRoom;
          this.curriculumCreateData["term"] = this.selectTerm;

          // if(this.curriculumCreateData._id){
          //   this.curriculumCreateData.subject = this.curriculumCreateData.subject
          // }else{
            this.curriculumCreateData["subject"] = this.selectSubject
         // }
         
          
      

          const response = await ViService.viXPost(
            "/academic/curriculumn/createOne",
            this.curriculumCreateData,
            userData.token
          );

          if (response.isSuccess) {
            // const resultData = secureUI.secureGet(response.data);
            this.$toasted.success(response.message);
            this.closeCurriculumPopUp();

         

            await this.getCurriculumInfo(this.selectTerm,this.selectSubject);

            // for (let x of Object.values(this.curriculumInfo)) {
            //   x.isView = false;
            // }

         // this.curriculumInfo[data].isView = true;
          } else {
            this.$toasted.error(response.message);
          }
        // } else {
        //   this.$toasted.error("Subject Already Created");
         }
      }else{
        this.$toasted.error('Please fill all the required details');
      }
    }
    },
    async removeDocument(index,key){
        let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
          let newList = []

        //  this.candidateEditDetails.eligibilityCriteriaDocuments.forEach((x, i) => {
        //  if(i == index){
        //    x.key = '',
        //    x.path = ''
        //  }
        //  newList.push(x)
				// });

        const response = await ViService.viXPut(
          `/document/removeTeacherMaterialDocument/${key}`,
          newList,
          userData.token
        );
        
        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.documentList.splice(index,1);

          this.updateTeacherMaterials()
        // this.candidateEditDetails.eligibilityCriteriaDocuments = newList
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      
      
     
    },
    async updateTeacherMaterials() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let allowSave = true;
       
        if (allowSave) {
         
          
          this.addMaterial.documents = this.documentList
         
          const response = await ViService.viXPost(
            "/academic/curriculumn/updateTeaherMaterial",
            this.addMaterial,
            userData.token
          );

          if (response.isSuccess) {
          
          } else {
            this.$toasted.error(response.message);
          }
      
         }
      }
    },
    async updateFile(docType) {
       let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const docFileDetails = this.$refs[docType].files[0]
        
        if(docFileDetails){

          const fd = new FormData();

          fd.append(
            "doc",
            docFileDetails,
            docFileDetails.name,
            docFileDetails.actType
          );

          const response = await ViService.viPutOnly(
            `/document/uploadFile/teacherMaterial`,
            fd,
            userData.token
          );
          
          if(response.isSuccess){
            this.documentList.push({
              name: docFileDetails.name,
              url: response.value.url,
              date: new Date()
            })
           this.updateTeacherMaterials()

          }else{
            console.log("response",response)
          }

        }
        
        document.getElementById("importExportID").value=null; 

      }

    },
    async updateCurriculamFile(docType) {
       let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        if(this.selectSubject){
        const docFileDetails = this.$refs[docType].files[0]
        
        if(docFileDetails){

          const fd = new FormData();

          fd.append(
            "doc",
            docFileDetails,
            docFileDetails.name,
            docFileDetails.actType
          );

          // this.curriculumCreateData["class"] = this.classId;
          // this.curriculumCreateData["classroom"] = this.classRoom;
          // this.curriculumCreateData["term"] = this.selectTerm;
          // if(this.curriculumCreateData._id){
          //   this.curriculumCreateData.subject = this.curriculumCreateData.subject
          // }else{
          //   this.curriculumCreateData["subject"] = this.selectSubject
          // }

          const response = await ViService.viPutOnly(
            `/document/uploadCurriculamFile?term=${this.selectTerm}&class=${this.classId}&classroom=${this.classRoom}&subject=${this.selectSubject}`,
            fd,
            userData.token
          );

          if(response.isSuccess){
            this.$toasted.success(response.message);

          //   this.documentList.push({
          //     name: docFileDetails.name,
          //     url: response.value.url,
          //     date: new Date()
          //   })
          //  this.updateTeacherMaterials()
          this.getCurriculumInfo(this.selectTerm,this.selectSubject);


          }else{
            this.$toasted.error(response.message);

          }

        }
        
        document.getElementById("importExportID").value=null; 

      }else{
        document.getElementById("importExportID").value=null; 

        this.$toasted.error("Please select subject and upload a file");
      }
    }

    },
    
    async updateSortingTopic() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let allowSave = true;
       
        if (allowSave) {
       

          const response = await ViService.viXPost(
            "/academic/curriculumn/updateTopicSorting",
            this.curriculumInfo,
            userData.token
          );

          if (response.isSuccess) {
            this.$toasted.success(response.message);
            this.closeviewSortingPopUp();
            this.getCurriculumInfo(this.selectTerm,this.selectSubject);
          } else {
            this.$toasted.error(response.message);
          }
       
         }
      }
    },
    changeTerm(id,subid){
       this.selectSubject = null
       this.getCurriculumInfo(id,subid)
    },

    async getCurriculumInfo(id,subId) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        if(id){
        let requestBody = {
          class: this.classId,
          classroom: this.classRoom,
          term: id,
          subject: subId || null

        };
        const response = await ViService.viXPost(
          "/academic/curriculumn_info",
          requestBody,
          userData.token
        );

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          this.curriculumInfo = resultData;
          for (let x of Object.keys(this.curriculumInfo)) {
            for(let y of this.curriculumInfo[x][x]){
              y.startDate = y.startDate ? y.startDate.substring(0, 10) : null,
              y.toDate = y.toDate ? y.toDate.substring(0, 10) : null
            }

          //allTermList.push(x);
          }
          // this.curriculumInfo = this.curriculumInfo.sort(function (x, y) { return x.order - y.order;});
          // if (this.curriculumInfo.length == 0) {
          //   this.isCreateNew = true;
          // }
        } else {
          this.$toasted.error(response.message);
        }
       }else{
         this.curriculumInfo = {}
         this.selectSubject = null
      }
      }
    },
    getCurriculumInfoWithSubject(id){
    this.getCurriculumInfo(this.selectTerm,id)
    },
    getSubjectName(id) {
      if (id) {
        if (this.subjectList.length > 0) {
          let details = this.subjectList.filter((x) => x._id == id);
          if (details.length > 0) {
            return details[0].subjectName;
          } else {
            return "";
          }
        } else {
          return "";
        }
      }
    },
    async getSubjectList() {
      this.teacherAllowSubjects = []
      
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/class/getSubjectAssignClassList?viewType=list&classId=${this.classId}`,
          userData.token
        );
        if (response.isSuccess) {
          this.subjectList = response.data
          if(this.subjectList.length > 0){
            
            if(this.assignedSubjects.length > 0){
              const filteredItems = this.subjectList.filter(item => this.assignedSubjects.includes(item._id.toString()));

            this.teacherAllowSubjects = filteredItems
            }else{
              this.teacherAllowSubjects = this.subjectList
            }
          
          }
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    openUpdateCurriculumPopUp(index) {
      let details = this.curriculumInfo[index];
      
      this.curriculumCreateData.topic = details.topic
      this.curriculumCreateData.noOfClass = details.noOfClass
      this.curriculumCreateData.status = details.status
      this.curriculumCreateData.startDate = details.startDate.substring(0, 10);
      this.curriculumCreateData.toDate = details.toDate.substring(0, 10);
      this.curriculumCreateData.subject = details.subject._id;
     // this.curriculumCreateData.topics = details.topics;
      this.curriculumCreateData._id = details._id;

      this.$bvModal.show("addCurriculumPopUp");
    },
    viewUpdateCurriculumPopUp(index) {
      let details = this.curriculumInfo[index];

      this.curriculumCreateData.subject = details.subject._id;
      this.curriculumCreateData.topic = details.topic
      this.curriculumCreateData.noOfClass = details.noOfClass
      this.curriculumCreateData.status = details.status
      this.curriculumCreateData.startDate = details.startDate.substring(0, 10);
      this.curriculumCreateData.toDate = details.toDate.substring(0, 10);
    //  this.curriculumCreateData.topics = details.topics;
      this.curriculumCreateData._id = details._id;

      this.$bvModal.show("viewCurriculumPopUp");
    },
    viewSortingPopUp(index) {
      this.$bvModal.show("sortingTopicPopUp");
    },
    viewmaterialCurriculamPopup(data,index,id) {
      let details = this.curriculumInfo[data][data][index];
      
      this.addMaterial = details;
      this.documentList = this.addMaterial.documents
     
      this.$bvModal.show("materialCurriculamPopup");
    },
    closePoup() {
      this.getCurriculumInfo(this.selectTerm,this.selectSubject);
      this.$bvModal.hide("materialCurriculamPopup");
    },
    
    
    closeviewSortingPopUp(){
      this.$bvModal.hide("sortingTopicPopUp");
    },

    

    async getClassTerm() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/class/getAllTerm?classId=${this.classId}`,
          userData.token
        );
        if (response.isSuccess) {
          let data = secureUI.secureGet(response.data);
          this.termList = data || [];

          if (this.termList.length > 0) {
            if (this.termList.length == 1) {
            this.selectTerm = this.termList[0]._id;
            this.getCurriculumInfo(this.termList[0]._id,null);

            var startDate = new Date(this.termList[0].startDate);
            var endDate = new Date(this.termList[0].endDate);

            this.minDate = this.dateFormat(startDate)
        
            this.maxDate =  this.dateFormat(endDate)
            }else{
            let currentDate = new Date()

            let checkData = this.termList.filter( x => (currentDate >= new Date(x.startDate)) && (currentDate <= new Date(x.endDate)))
            if(checkData.length > 0){

              this.selectTerm = checkData[0]._id;
              this.getCurriculumInfo(checkData[0]._id,null);

              var startDate = new Date(checkData[0].startDate);
              var endDate = new Date(checkData[0].endDate);

              this.minDate = this.dateFormat(startDate)
          
              this.maxDate =  this.dateFormat(endDate)

            }else{
              let checkfutureDates = this.termList.filter( x => (new Date(x.startDate) > currentDate))
             if(checkfutureDates.length > 0){
              this.selectTerm = checkfutureDates[0]._id;
              this.getCurriculumInfo(checkfutureDates[0]._id,null);

              var startDate = new Date(checkfutureDates[0].startDate);
              var endDate = new Date(checkfutureDates[0].endDate);

              this.minDate = this.dateFormat(startDate)
          
              this.maxDate =  this.dateFormat(endDate)
             }else{
              this.selectTerm = this.termList[0]._id;
              this.getCurriculumInfo(this.termList[0]._id,null);

              var startDate = new Date(this.termList[0].startDate);
              var endDate = new Date(this.termList[0].endDate);

              this.minDate = this.dateFormat(startDate)
          
              this.maxDate =  this.dateFormat(endDate)
            }
            }
           
            }
          }
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }

      this.$bvModal.show("viewSectionPopUp");
    },
    dateFormat(value){
      if (value) {
        let date = new Date(value);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2,"0"); // Months are zero-based, so we add 1
        const day = String(date.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
    }
    },
    async confirmDelete(id,term) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viDelete(
          `/academic/curriculumn/delete/${id}/${term}`,
          userData.token
        );

        if (response.isSuccess) {
          this.getCurriculumInfo(this.selectTerm,this.selectSubject);

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async removeCurriculamSub(data,index,id) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {

        if(id){
          const response = await ViService.viDelete(
              `/academic/curriculumnTopic/${id}`,
              userData.token
            );

            if (response.isSuccess) {
             // this.getCurriculumInfo(this.selectTerm,this.selectSubject);
             this.curriculumInfo[data][data].splice(index, 1);
              this.$toasted.success(response.message);
            } else {
              this.$toasted.error(response.message);
              if (response.message == "Your session has expired, please login") {
                localStorage.removeItem("user");
                this.$router.push("/login");
              }
            }
        }else{
          this.curriculumInfo[data][data].splice(index, 1);

        }

       
      }
    },
    openAssignTeacherPopUp() {
      this.$bvModal.show("assignTeacherPopUp");
    },
    closeAssignTeacherPopUp() {
      this.$bvModal.hide("assignTeacherPopUp");
    },
    openResultPopUp() {
      this.$bvModal.show("resultPopUp");
    },
    closeResultPopUp() {
      this.$bvModal.hide("resultPopUp");
    },

    openClassPerformance() {
      this.$bvModal.show("classPerformancePopUp");
    },
    closeClassPerformancePopUp() {
      this.$bvModal.hide("classPerformancePopUp");
    },
    openCurriculumPopUp() {
      this.$bvModal.show("addCurriculumPopUp");
    },
    closeViewCurriculumPopUp() {
    //  (this.selectSubject = null),
        // (this.selectTerm = null)
        (this.curriculumCreateData = {
          subject: null,
          topics: [],
        });
      this.$bvModal.hide("viewCurriculumPopUp");
    },
    closeCurriculumPopUp() {
      this.$bvModal.hide("addCurriculumPopUp");
      //(this.selectSubject = null),
        // (this.selectTerm = null)
        // this.curriculumCreateData = {
        //   subject: null,
        //   topics: []
        // };
        this.curriculumCreateData._id = null,
        this.curriculumCreateData.topic = "",
        this.curriculumCreateData.noOfClass = null,
        this.curriculumCreateData.status = "",
        this.curriculumCreateData.startDate = null,
        this.curriculumCreateData.toDate = null
    },

    openCreateTimeTablePopUp() {
      this.$bvModal.show("createTimeTablePopUp");
    },
    closeCreateTimeTablePopUp() {
      this.$bvModal.hide("createTimeTablePopUp");
    },
    enableSetupTimeTable() {
      this.showSetupTimeTable = true;
    },
    closeSetupTimeTable() {
      this.showSetupTimeTable = false;
    },
  },
  components: {
    FullCalendar,
    'app-dragabble': draggable
  },
};
</script>
